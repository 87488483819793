import request from '@/utils/request'

/**
 * 角色管理
 */
//推手业绩统计列表
export const distStatistList = (data)=> request('/pc/statis/distributor/performance',data);

//推手收益
export const distCommssionList = (data)=> request('/pc/commission/distributor',data);

//推手收益类型
export const distfilterBoxList = ()=> request('/pc/distributorRole/filterBoxList');

//推手收益明细头部总计
export const distdetailCollect = (data)=> request('/pc/commission/distributor/detailCollect',data);

//推手收益明细列表
export const distdetailList = (data)=> request('/pc/commission/distributor/detailList',data);

//店员员业绩统计列表
export const employeeStatistList = (data)=> request('/pc/statis/mallEmployee/performance',data);

//员工提成
export const employeecommissionList = (data)=> request('/pc/commission/mallEmployee',data);

//员工提成明细汇总
export const employeedetailCollect = (data)=> request('/pc/commission/mallEmployee/detailCollect',data);

//员工提成明细列表
export const employeedetailList = (data)=> request('/pc/commission/mallEmployee/detailList',data);

//推手业绩统计明细
export const distStatistdetailList = (data)=> request('/pc/statis/distributor/performance/detailList',data);

//店员业绩统计明细
export const employeeperdetailList = (data)=> request('/pc/statis/mallEmployee/performance/detailList',data);

//提现中心
export const WithdrawalList = (data)=> request('/pc/commissionWithdrawal/list',data);

//发卷宝活动-列表
export const CouponGiftList = (data)=> request('/pc/ActivityCouponGift/List',data);

//发卷宝活动-添加编辑
export const CouponGiftEdit = (data)=> request('/pc/ActivityCouponGift/Edit',data);

//发卷宝活动-获取详细
export const CouponGiftModel = (data)=> request('/pc/ActivityCouponGift/Model',data);

//提现中心-审核通过
export const WithdrawalcheckSuccess = (data)=> request('/pc/commissionWithdrawal/checkSuccess',data);

//提现中心-审核否决
export const WithdrawalcheckFail = (data)=> request('/pc/commissionWithdrawal/checkFail',data);

//提现中心-修改提现账号
export const WithdrawalupdateAccount = (data)=> request('/pc/commissionWithdrawal/updateWithdrawalAccount',data);

//获取模式配置数据(推手) LEE
export const distributorconfig = (data)=> request('/pc/mall/mallmemberdistributorconfig',data);

//保存模式配置数据(推手) LEE
export const savedistributorconfig = (data)=> request('/pc/mall/savemallmemberdistributorconfig',data);

//设置商城基本数据 LEE
export const savemallbaseinfo = (data)=> request('/pc/mall/savemallbaseinfo',data);

//设置商城基本数据 LEE
export const mallbaseinfo = (data)=> request('/pc/mall/mallbaseinfo',data);

//获取海报列表 LEE
export const infoposterlist = (data)=> request('/pc/infoposter/infoposterlist',data);

//删除海报LEE
export const infoposterdelete = (data)=> request('/pc/infoposter/infoposterdelete',data);

//海报排序 
export const infoPosterSortEdit = (data)=> request('/pc/infoposter/infoPosterSortEdit',data);

//新增海报 LEE
export const infoposteradd = (data)=> request('/pc/infoposter/infoposteradd',data);

//推手查询 LEE
export const distributorsearch = (data)=> request('/pc/member/memberdistributormanager',data);

//推手添加 LEE
export const memberdistributoradd = (data)=> request('/pc/member/memberdistributoradd',data);

//推手取消授权验证数据 LEE
export const memberdistibutorcancelvalitedata = (data)=> request('/pc/member/memberdistibutorcancelvalitedata',data);

//推手授权取消 LEE
export const memberdistibutorcancel = (data)=> request('/pc/member/memberdistibutorcancel',data);

//获取推手推荐列表 LEE
export const memberdistributorrecommendlist = (data)=> request('/pc/member/memberdistributorrecommendlist',data);

//推手概况 LEE
export const memberdistributorstatistics = (data)=> request('/pc/member/memberdistributorstatistics',data);

//通过手机号查询员工信息 LEE  店员查询使用
export const employeeinfobyphone = (data)=> request('/pc/employee/employeeinfobyphone',data);

//通过手机号查询信息 LEE  推荐人查询使用
export const recommendmemberinfobyphone = (data)=> request('/pc/member/memberinfobyphone',data);

//修改推手等级 LEE 
export const changememberdistributorrole = (data)=> request('/pc/member/changememberdistributorrole',data);

//修改直接推荐人 LEE
export const changememberdistributorrecommend = (data) => request('/pc/member/changememberdistributorrecommend', data);

//员工离职验证数据 LEE
export const employeedeparturevalitedata = (data) => request('/pc/employee/employeedeparturevalitedata', data);

//删除分销员等级
export const malldeletedistributorrole = (data) => request('/pc/mall/deletedistributorrole', data);



//推手导入是否存在进行中
export const membermemberdistributorisexistprogress = (data) => request('/pc/member/memberdistributorisexistprogress', data);
//导入推手
export const membermemberdistributorimport = (data) => request('/pc/member/memberdistributorimport', data);
//导入推手是否成功
export const membermemberdistributorimportiscomplete = (data) => request('/pc/member/memberdistributorimportiscomplete', data);