<template>
	<div style="background: #fff;padding: 15px;">
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id + ''"></el-tab-pane>
		</el-tabs>
		<div v-if="currentTab == 0">
			<div class="filter-container">
				<div class="filter-item">
					<div style="display: flex;align-items: center;">
						<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
						<el-input v-model="searchKey" placeholder="手机号、微信昵称、姓名"></el-input>
						<label class="label" style="white-space: nowrap;margin-left: 15px;">推手级别: </label>
						<el-select v-model="authType" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in activityStateList" :key="item.Id" :label="item.DistributRoleName" :value="item.Id">
							</el-option>
						</el-select>

						<el-button type="primary" style="margin-left: 15px;width: 90px;" size="small" @click="goodsSearch">查询</el-button>
						<button-permissions :datas="'ysMoneygetExport'">
							<el-button style="margin-left:20px;width: 90px;" size="small" @click="exportFun">导出</el-button>
						</button-permissions>

					</div>
				</div>
			</div>

			<!-- 门店管理表格 -->
			<div class="table-container">
				 <!-- :cell-style="{background:'#fff'}" -->
				<el-table  :data="distStatistLists" style="width: 100%;" v-loading="loading">
					<el-table-column label="推手" :key='1' width="250px">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
								<div style="margin-left: 10px;height: 50px;line-height: 50px;">{{scope.row.Name ? scope.row.Name : scope.row.WxNickname ? scope.row.WxNickname : scope.row.Phone}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Phone" label="手机号" :key='2'></el-table-column>
					<el-table-column prop="DistributRoleName" label="推手级别" :key='3'></el-table-column>
					<el-table-column prop="TotalBalance" label="累计收益" :key='4'></el-table-column>
					<el-table-column prop="DrawedBalance" label="累计提现" :key='5'>
						<template slot-scope="scope">
							<div @click='pushTomoneyRecord(scope.row)' v-if="scope.row.DrawedBalance>0" style="color:#409EFF;cursor: pointer;">{{scope.row.DrawedBalance}}</div>
							<div v-else>{{scope.row.DrawedBalance}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="UnfinishBalance" label="待结算" :key='6'></el-table-column>
					<el-table-column prop="WaitDrawBalance" label="待提现" :key='7'></el-table-column>
					<el-table-column prop="DrawingBalance" label="提现中" :key='8'></el-table-column>
					<el-table-column prop="CancelBalance" label="已扣除" :key='9'></el-table-column>
					<!-- <el-table-column label="操作" width="140" :key='10'>
						<template slot-scope="scope">
							<el-button type="text" @click="achievementDetail(scope.row)">收益明细</el-button>
						</template>
					</el-table-column> -->
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>


		<!-- //收益明细 -->

		<!--  -->
		<div v-if="currentTab == 1">
			<div>
				<div class="filter-container">
					<div class="filter-item">
						<div style="display: flex;align-items: center;">
							<label class="label" style="white-space: nowrap;font-weight: 700;">关键字: </label>
							<el-input v-model="searchKey1" placeholder="手机号、微信昵称、姓名"></el-input>
						</div>
					</div>
					<div class="filter-item">
						<div style="display: flex;align-items: center;">
							<label class="label" style="width: 70px;white-space: nowrap;">收益时间: </label>
							<el-date-picker type="datetime" placeholder="选择日期" v-model="startTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
							 format="yyyy-MM-dd HH:mm:ss" default-time='00:00:00'></el-date-picker> ~
							<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
							 format="yyyy-MM-dd HH:mm:ss" default-time='23:59:59'></el-date-picker>
							<!-- <el-date-picker v-model="startTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options='maxOptions'
							 placeholder="开始时间">
							</el-date-picker>
							<span style="margin: 0 10px;">~</span>
							<el-date-picker v-model="endTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options='minOptions'
							 placeholder="结束时间">
							</el-date-picker> -->
						</div>
					</div>
				</div>

				<div class="filter-container">
					<div class="filter-item">
						<label class="label" style="white-space: nowrap;margin-left: 15px;">推手级别: </label>
						<el-select v-model="authType1" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in activityStateList" :key="item.Id" :label="item.DistributRoleName" :value="item.Id">
							</el-option>
						</el-select>
					</div>

					<div class="filter-item">
						<label class="label" style="white-space: nowrap;">收益类型: </label>
						<el-select v-model="commssionType" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="(item,index) in commssionTypeList" :key="index" :label="item.type" :value="item.id">
							</el-option>
						</el-select>
					</div>

					<div class="filter-item">
						<label class="label" style="white-space: nowrap;margin-left: 15px;">收益状态: </label>
						<el-select v-model="commssionState" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="(item,index) in commssionStateList" :key="index" :label="item.type" :value="item.id">
							</el-option>
						</el-select>
						<el-button type="primary" style="margin-left:30px;width: 90px;" size="small" @click="commssionSearch">查询</el-button>
						<button-permissions :datas="'ysMoneyDetailExport'">
							<el-button style="margin-left:20px;width: 90px;" size="small" @click="exportFun1">导出</el-button>
						</button-permissions>
					</div>
				</div>
			</div>
			<div style="font-size: 14px;color: #101010;margin-top: 10px;background: #F0F2F5;padding: 15px 10px;margin-bottom: 10px;">
				<span style="margin-right: 100px;">累计收益:{{commssionTotal.TotalBalance}}</span>
				<span style="margin-right: 100px;">待结算收益:{{commssionTotal.UnfinishBalance}}</span>
				<span style="margin-right: 100px;">已结算收益:{{commssionTotal.WaitDrawBalance}}</span>
				<span>已扣除收益:{{commssionTotal.CancelBalance}}</span>
			</div>
			<div class="table-container">
				<el-table :data="commssionDetailList" style="width: 100%;" v-loading="loading">
					<el-table-column label="推手" :key='11' width="250px">
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<!-- <img :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;"> -->
								<div style="height: 50px;line-height: 50px;">{{scope.row.Name ? scope.row.Name :scope.row.WxNickname?scope.row.WxNickname:''}}</div>
								
							</div>
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="DistributRoleName" label="推手级别" :key='12'></el-table-column>
					<!-- <el-table-column prop="Phone" label="手机号" :key='12'></el-table-column> -->
					<el-table-column prop="AddTime" label="收益时间" :key='13'></el-table-column>
					<el-table-column prop="AwardMoney" label="收益(元)" :key='14'></el-table-column>
					<el-table-column prop="StateValue" label="状态" :key='15'></el-table-column>
					<el-table-column label="订单编号" width="220px" :key='16'>
						<template slot-scope="scope">
							<div style="white-space: nowrap;color: #409EFF;cursor: pointer;" v-if="scope.row.OrderNo" @click='pushTorderDetail(scope.row)'>{{scope.row.OrderNo}}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ProductName" label="商品" :key='17'>
						<template slot-scope="scope">
							<div class="ellipsisCommon" style="width: 120px;">{{scope.row.ProductName}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="CommissionRateValue" label="收益比" :key='18'></el-table-column>

					<el-table-column label="收益备注" width="120" :key='19'>
						<template slot-scope="scope">
							<el-tooltip class="item-tooltip" popper-class="comment-reply-item-tooltip" effect="light" placement="top-start">
								<div slot="content">
									<div style="width: 120px;">{{scope.row.Remark}}</div>
								</div>
								 <!-- style="-webkit-line-clamp: 4;line-clamp:4" -->
								<div class="ellipsisCommon">{{scope.row.Remark}}</div>
							</el-tooltip>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="Remark" label="收益备注" :key='19'></el-table-column> -->
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total1" style="margin-top:20px;float:right;" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
					 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total1">
					</el-pagination>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		distCommssionList,
		distfilterBoxList,
		// distdetailCollect,
		distdetailList
	} from '@/api/wyUsedInterface.js'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				goUrls: config.GO_URL,
				currentTab: 0, //当前选择的tab
				exportUrl: config.EXPORT_URL,
				//新增
				listStateList: [{
						id: 0,
						value: '收益概况'
					},
					{
						id: 1,
						value: '收益明细'
					}
				],
				listState: '0',
				commssionTypeList: [{
						id: 1,
						type: '自营销售收益'
					},
					{
						id: 2,
						type: '直推销售收益'
					}
				],
				activityStateList: [],
				commssionStateList: [{
						id: 0,
						type: '待结算'
					},
					{
						id: 1,
						type: '已结算'
					},
					{
						id: 2,
						type: '已失效'
					}
				],
				searchKey: '',
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				authType: null,
				commssionType: null,
				commssionState: null,
				endTime: '',
				startTime: '',
				achievementShow: false,
				distStatistLists: [],
				currentEditRow: {}, //当前查看详情的行
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				commssionTotal: {
					CancelBalance: 0,
					TotalBalance: 0,
					UnfinishBalance: 0,
					WaitDrawBalance: 0
				},
				commssionDetailList: [],
				imgUrl: config.IMG_BASE,
				defaultHeader: config.DEFAULT_HEADER,

				//明细筛选
				searchKey1: '',
				authType1: null,

			};
		},
		beforeMount() {
			this.getListData(),
			this.getAuthList()
			this.getCurrentMonthFirst()
			this.getCurrentMonthLast()
		},
		methods: {
			//新窗口打开订单详情
			pushTorderDetail(row){
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + (row.OrderType==0?'/order/orderDetailpage':'/videoShop/videoOrderDetails')+'?Id=' + row.OrderNo
				window.open(url)
				
				// this.$router.push({
				// 	path: '/order/orderDetailpage',
				// 	query: {
				// 		Id: record.OrderNo ? record.OrderNo : 0
				// 	}
				// });
			},
			//跳转体现记录
			pushTomoneyRecord(record){
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : '';
				var mod = localStorage.getItem('mlmzDistributionModel');
				let url = '';
				
				if (mod === '0'){
					url = this.goUrls + headsUrls + '/assets/withDrawal?listState=1&searchKey=' + record.Phone
				}else{
					url = this.goUrls + headsUrls + '/agent/assets/withDrawal?listState=1&searchKey=' + record.Phone
				}
				
				window.open(url)
			},
			getCurrentMonthFirst() {
				var date = new Date()
				date.setDate(1)
				var month = parseInt(date.getMonth() + 1)
				var day = date.getDate()
				if (month < 10) month = '0' + month
				if (day < 10) day = '0' + day
				this.startTime = date.getFullYear() + '-' + month + '-' + day + ' ' + "00:00:00"
			},
			getCurrentMonthLast() {
				const year = new Date().getFullYear()
				const month = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
				const date = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
				const hh = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
				const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
				const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
				this.endTime = year + '-' + month + '-' + date + ' ' + 23 + ':' + 59 + ':' + 59
			},
			// 收益概况导出
			async exportFun() {
				try {
					let url = this.exportUrl + '/pc/commission/distributor?' +
						'&Keywords=' + this.searchKey +
						'&MemberDistributorRoleId=' + (this.authType?this.authType:'')
					window.open(url);
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			//收益明细导出
			async exportFun1() {
				try{
					let url = this.exportUrl + '/pc/commission/distributor/detailList?' +
						'&Keywords=' + this.searchKey1 +
						'&Type=' + (this.commssionType?this.commssionType:'') +
						'&StartTime=' + (this.startTime?this.startTime:'') +
						'&EndTime=' + (this.endTime?this.endTime:'') + 
						'&State=' + (this.commssionState==null?'':this.commssionState) + 
						'&MemberDistributorRoleId=' + (this.authType1?this.authType1:'')
						window.open(url);

						// console.log(url)
				}catch(err){
					
				}finally{
					
				}
			},
			filterFun(tab) {
				if (tab.index == '0') {
					this.currentTab = 0;
					this.getListData();
				}
				//收益明细
				else if (tab.index == '1') {
					this.currentTab = 1;
					this.getDetail()
					// this.getListData();
				}
			},
			//收益概况
			async getListData() {
				this.loading = true
				try {
					let data = {
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
						Keywords: this.searchKey,
						MemberDistributorRoleId: this.authType
					}
					let result = await distCommssionList(data)
					this.distStatistLists = result.Result.Results
					this.Total = result.Result.Total
					console.log(result)
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
			async getAuthList() {
				try {

					let result = await distfilterBoxList()
					this.activityStateList = this.activityStateList.concat(result.Result)

				} catch (e) {
					//TODO handle the exception
				}
			},
			goodsSearch() {
				this.currentPage = 1
				this.getListData()
			},
			achievementDetail(row) {
				this.currentEditRow = row
				this.achievementShow = true
				this.currentPage1 = 1
				this.getDetail()
			},
			async getDetail() {
				try {
					let data2 = {
						Keywords: this.searchKey1,
						Type: this.commssionType,
						StartTime: this.startTime,
						EndTime: this.endTime,
						State: this.commssionState,
						MemberDistributorRoleId: this.authType1,
						Skip: (this.currentPage1 - 1) * this.pageSize1,
						Take: this.pageSize1, // 取的数据
					}
					let result2 = await distdetailList(data2)
					this.commssionDetailList = result2.Result.Results
					if (this.currentPage1 == 1) {
						this.commssionTotal.TotalBalance = result2.Result.TotalBalance
						this.commssionTotal.UnfinishBalance = result2.Result.UnfinishBalance
						this.commssionTotal.WaitDrawBalance = result2.Result.WaitDrawBalance
						this.commssionTotal.CancelBalance = result2.Result.CancelBalance
					}

					this.Total1 = result2.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getListData();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getListData();
			},
			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getDetail()
				// this.achievementDetail();
			},

			// 翻页
			handleCurrentChange1(val) {
				console.log(`当前页: ${val}`);
				this.currentPage1 = val;
				this.getDetail()
				// this.achievementDetail();
			},

			commssionSearch() {
				this.getDetail()
			}
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.startTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime)
						}
						// else{
						//            return time < Date.now()
						//          }
					}
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(limitTime)
								// ||time < Date.now()
							)
						}
					}
				}
			}
		},
	}
</script>

<style lang="less" scoped>
	.ellipsisCommon {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
